import * as React from "react";
import { useEffect } from "react";
import i18n from "../../i18n";
import { CurrentPathProvider } from "../hooks/current_path";
import Helmet from "react-helmet";
import { withPreview } from "gatsby-source-prismic";

declare global {
  interface Window {
    __gatsby_language: string;
  }
}

const Layout: React.FC<{
  location: Location;
  pageContext: {
    language: string;
  };
}> = ({ children, location, pageContext }) => {
  useEffect(() => {
    i18n.changeLanguage(pageContext.language);
    if (typeof window !== "undefined") {
      window.__gatsby_language = pageContext.language;
    }
  }, [pageContext.language]);

  return (
    <CurrentPathProvider path={location.pathname}>
      <div>
        <Helmet>
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.12.1/css/all.min.css"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href="/favico/apple-touch-icon.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href="/favico/favicon-32x32.png"
          />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/favico/favicon-16x16.png"
          />
          <link rel="manifest" href="/favico/site.webmanifest" />

          {/* <!-- Start of HubSpot Embed Code --> */}
          <script
            type="text/javascript"
            id="hs-script-loader"
            async
            defer
            src="//js.hs-scripts.com/5096486.js"
          />
          {/* <!-- End of HubSpot Embed Code --> */}

          <script>{`(function(){ window.ldfdr = window.ldfdr || {}; (function(d, s, ss, fs){ fs = d.getElementsByTagName(s)[0]; function ce(src){ var cs = d.createElement(s); cs.src = src; setTimeout(function(){fs.parentNode.insertBefore(cs,fs)}, 1); } ce(ss); })(document, 'script', 'https://sc.lfeeder.com/lftracker_v1_Xbp1oaEV2qX8EdVj.js'); })();`}</script>
        </Helmet>
        {children}
      </div>
    </CurrentPathProvider>
  );
};

export default withPreview(Layout);
